<template lang="pug">
  mp-multiselect(
    placeholder='Select a project',
    :searchable='true',
    v-model='project',
    :allow-empty='false',
    :options='projects',
    v-on='$listeners'
  )
</template>

<script>
import projectsQuery from "@/graphql/queries/core/projects/ProjectDropdown.gql";
import MpMultiselect from "./MpMultiselect.vue";

export default {
  components: {
    MpMultiselect
  },
  props: {
    value: Object,
    integrationType: String,
    integration: Object,
    mode: {
      type: String,
      default: "normal"
    }
  },
  apollo: {
    projects: {
      query: projectsQuery,
      variables() {
        return this.queryVariables;
      },
      update(data) {
        return data.projects.edges.map(edge => edge.node);
      }
    }
  },
  data() {
    return {
      project: this.value
    };
  },
  computed: {
    queryVariables() {
      const vars = {
        integrationType: this.integrationType
      };

      if (this.mode === "integration") {
        Object.assign(vars, {
          integrationId: this.integrationId
        });
      }

      return vars;
    },
    integrationId() {
      if (this.integration) {
        return this.integration.id;
      }
      if (this.project.integration) {
        return this.project.integration.id;
      }

      return null;
    }
  },
  watch: {
    value(newProject) {
      this.project = newProject;
    }
  }
};
</script>
