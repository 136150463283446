<template lang="pug">
  tr
    td.handle.centered
      i.grey.ellipsis.vertical.icon
    td.centered(width='50px') {{ index + 1 }}
    td.input.category.nobreak
      mp-multiselect(
        v-model='lineItem.item',
        :options='items',
        :show-create-button='true',
        group-values='items',
        group-label='category',
        :group-select='false',
        placeholder='Select an item',
        create-label='Add a new item',
        @input='itemSelected',
        @create='$emit("create", "item")',
      )

    input-wrapper(v-if='description', :v='v.description')
      input.ui.input(
        type='text',
        v-model='lineItem.description',
        placeholder='Description',
        @blur='v.description.$touch',
        @input='$emit("input", lineItem)'
      )

    input-wrapper(:v='v.quantity', :classes='["nowrap"]')
      number-input(
        v-model='lineItem.quantity',
        placeholder='Quantity',
        classes='right aligned segment'
        @blur='v.quantity.$touch',
        @keydown.enter.prevent='',
        @input='$emit("input", lineItem)'
      )

    input-wrapper(:v='v.unitCost', :classes='["nowrap"]')
      number-input(
        v-model='lineItem.unitCost',
        placeholder='Cost',
        type='currency',
        :currency='currency',
        ref='unitCost',
        @blur='v.unitCost.$touch',
        @keydown.enter.prevent='',
        @input='$emit("input", lineItem)'
      )

    input-wrapper(:v='v.account', :classes='["nowrap"]')
      mp-multiselect(
        v-model='lineItem.account',
        :options='accounts',
        :show-create-button='true',
        track-by='id',
        label='name',
        placeholder='Select an account',
        create-label='Add a new account',
        @create='$emit("create", "account")',
        @input='accountSelected'
      )

    input-wrapper(:v='v.taxRate', :classes='["nowrap"]')
      mp-multiselect(
        v-model='lineItem.taxRate',
        :options='availableTaxRates',
        :show-create-button='showQuickTaxRate',
        track-by='id',
        label='name',
        placeholder='Select a tax rate',
        create-label='Add a new tax rate',
        @create='$emit("create", "tax-rates")',
        @input='$emit("input", lineItem)'
      )
    input-wrapper(:classes='["nowrap"]')
      number-input(
        :read-only='true',
        :value='totalAmount',
        type='currency',
        :currency='currency')

    td(width='50px')
      mp-button(size='mini', basic, icon='delete', @click.prevent='deleteLineItem()')
</template>

<script>
import find from "ramda/src/find";
import propEq from "ramda/src/propEq";
import filter from "ramda/src/filter";
import FinanceLineItem from "@/mixins/FinanceLineItem";
import MpMultiselect from "@/components/inputs/dropdowns/MpMultiselect.vue";
import NumberInput from "@/components/inputs/NumberInput.vue";
import InputWrapper from "@/components/inputs/wrappers/TableCellInputWrapper.vue";
import MpButton from "@/components/elements/MpButton.vue";
import { initCap } from "@/helpers/StringHelpers";

export default {
  components: {
    MpMultiselect,
    NumberInput,
    InputWrapper,
    MpButton
  },
  mixins: [FinanceLineItem],
  props: {
    items: Array,
    taxRates: Array,
    accounts: Array,
    project: Object
  },
  computed: {
    taxTotal() {
      return 0;
    },
    totalAmount() {
      return this.quantity * this.unitCost;
    },
    quantity() {
      return this.lineItem.quantity || 0;
    },
    unitCost() {
      return this.lineItem.unitCost || 0;
    },
    availableTaxRates() {
      if (this.project === null || this.project === undefined) {
        return this.taxRates;
      }

      const { integration } = this.project;
      if (integration === null || integration === undefined) {
        return this.taxRates;
      }

      return this.filterTaxRates(integration, this.lineItem.account);
    },

    showQuickTaxRate() {
      if (this.project === null || this.project === undefined) {
        return true;
      }
      const { integration } = this.project;

      if (integration === null || integration === undefined) {
        return true;
      }

      return false;
    }
  },
  methods: {
    accountSelected() {
      // Deselect the existing tax rate if it's not an option
      const { taxRate } = this.lineItem;
      if (taxRate) {
        const filtered = find(propEq("id", taxRate.id))(this.availableTaxRates);
        if (!filtered) {
          this.lineItem.taxRate = null;
        }
      }

      this.$emit("input", this.lineItem);
    },
    itemSelected(item) {
      if (this.description) {
        this.lineItem.description = item.saleDescription;
      }
      if (!this.lineItem.quantity) {
        this.lineItem.quantity = 0;
      }

      this.lineItem.unitCost = item.saleAmount;
      if (item.saleTaxRate) {
        this.lineItem.taxRate = this.findTaxRate(item.saleTaxRate.id);
      }
      if (item.saleAccount) {
        this.lineItem.account = this.findAccount(item.saleAccount.id);
      }

      this.$emit("input", this.lineItem);
    },
    findAccount(accountId) {
      return find(propEq("id", accountId))(this.accounts);
    },
    findTaxRate(taxRateId) {
      return find(propEq("id", taxRateId))(this.taxRates);
    },
    filterTaxRates(integration, account) {
      if (!this.taxRates) {
        return [];
      }
      if (account === null || account === undefined) {
        return this.taxRates;
      }

      let { accountClass } = account;
      accountClass = initCap(accountClass);
      const propName = `canApplyTo${accountClass}`;

      const findAvailability = availability =>
        propEq("integration", integration.id)(availability);

      const search = taxRate => {
        let availability = find(findAvailability)(taxRate.accountAvailability);
        if (!availability) {
          availability = taxRate;
        }

        if (availability.integration) {
          return availability[propName];
        }

        return false;
      };

      return filter(search, this.taxRates);
    }
  }
};
</script>
