<template lang="pug">
  .container
    .page.loader(v-if='loading')
      .circle
        .ring

    template(v-else)
      .doubling.ui.grid
        .ten.wide.column
          page-title(:title='title')

        .six.wide.right.aligned.computer.only.column
          slot(name='title-actions')

      .hidden.ui.divider

      slot
</template>

<script>
import PageTitle from "./PageTitle.vue";

export default {
  components: {
    PageTitle
  },
  props: {
    title: String,
    loading: Boolean
  }
};
</script>
