<template lang="pug">
  base-modal(
    :name='name',
    classes='form',
    :title='title',
    :state='state',
    @closing='$emit("closing")')

    slot
</template>

<script>
import BaseModal from "@/components/modals/BaseModal.vue";

export default {
  components: {
    BaseModal
  },
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    state: {
      type: String,
      required: false,
      default: "none"
    }
  }
};
</script>

<style>
.mp.modal .actions,
.mp.modal .ui.form .actions {
  margin-top: 3em;
  text-align: left;
}
</style>
