export default {
  estimates: {
    form: {
      name: {
        content: `If this estimate is authorised and is used to create a project, then the new
                  project will take this name.`
      },
      gdv: {
        content: `The Gross Development Value or GDV is the total estimated value of the development.
                  ManagePlaces will use this value to determine the estimated profit throughout the
                  project.`
      }
    }
  }
};
