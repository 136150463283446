<template lang="pug">
thead
  tr.categories
    th(:colspan='detailsColSpan') Details
    th(colspan='2') Cost
    th(colspan='2') Tax
    th(colspan='2') Total

  tr
    th.center.aligned(width="25px")
    th.center.aligned #
    th(width='20%') Item
    th(v-if='description', width='20%') Description
    th(v-if='task', width='20%') Task
    th(width='100px') Qty.
    th(width='100px') Unit cost
    th Account
    th Tax rate
    th(width='100px') Amount
    th.actions
</template>

<script>
export default {
  props: ["description", "task"],
  computed: {
    detailsColSpan() {
      let cols = 3;

      if (this.description) {
        cols += 1;
      }
      if (this.task) {
        cols += 1;
      }

      return cols;
    }
  }
};
</script>
