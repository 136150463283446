<template lang="pug">
  .red.basic.ui.message(v-if='hasErrors')
    .header There appear to be some errors
    .list
      li(v-for='(error, index) in uniqueErrors', :key='index') {{ error }}
</template>

<script>
import { multiErrorExtractorMixin } from "vuelidate-error-extractor";
import uniq from "ramda/src/uniq";

export default {
  name: "BaseMultiErrorExtractor",
  extends: multiErrorExtractorMixin,
  computed: {
    uniqueErrors() {
      return uniq(this.activeErrorMessages);
    }
  }
};
</script>
