import reduce from "ramda/src/reduce";
import merge from "ramda/src/merge";

function prefix(level) {
  return "\u00a0".repeat(level * 5); // non-breaking space
}

function treeDropdownFormatter(tree) {
  const reducer = (currentList, currentItem) => {
    const currentNode = merge(currentItem.node, {
      label: prefix(currentItem.level) + currentItem.node.label
    });

    return currentList.concat(
      currentNode,
      treeDropdownFormatter(currentItem.children)
    );
  };
  return reduce(reducer, [], tree);
}

export default treeDropdownFormatter;
