export default {
  taxRates: {
    form: {
      displayRate: {
        content: "This is the rate that we will display to users."
      },
      effectiveRate: {
        content:
          "This is the rate we will use to calculate the total amount of tax."
      }
    }
  }
};
