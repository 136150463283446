<template lang="pug">
  mp-multiselect(
    :options='options',
    v-model='selectedType',
    :label='null',
    :track-by='null',
    :custom-label='label'
  )
</template>

<script>
import replace from "ramda/src/replace";
import toUpper from "ramda/src/toUpper";
import MpMultiselect from "./MpMultiselect.vue";

export default {
  components: {
    MpMultiselect
  },
  data() {
    return {
      options: ["asset", "equity", "expense", "liability", "revenue"]
    };
  },
  computed: {
    selectedType: {
      get() {
        return this.value;
      },
      set(type) {
        this.$emit("input", type);
      }
    }
  },
  methods: {
    label(value) {
      return replace(/^./, toUpper)(value.toLowerCase()).replace(/_/g, " ");
    }
  }
};
</script>
