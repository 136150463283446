<template lang="pug">
  span.resource.dropdown
    mp-multiselect(
      :placeholder='dropdownLabel',
      :searchable='searchable',
      v-model='resource',
      :loading='isLoading',
      :allow-empty='allowDeselection',
      :show-create-button='shouldShowCreateButton',
      :options='resources',
      :create-label='createLabel',
      :disabled='isDisabled',
      v-on='inputListeners'
      :search-fields="searchFields"
    )

    component(
      v-if='quickCreateComponent',
      :is='quickCreateComponent',
      @created='resourceCreated',
      :opts='quickCreateOpts')

</template>

<script>
import gql from "graphql-tag";
import filter from "ramda/src/filter";
import FormModal from "@/components/modals/FormModal.vue";
import MpMultiselect from "@/components/inputs/dropdowns/MpMultiselect.vue";

export default {
  components: {
    FormModal,
    MpMultiselect
  },
  props: {
    value: [String, Number, Object],
    dropdownLabel: String,
    query: String,
    queryFields: Array,
    quickCreateComponent: Object,
    quickCreateOpts: {
      type: Object,
      default() {
        return {};
      }
    },
    showCreateButton: Boolean,
    modelName: String,
    disabledIfSingle: Boolean,
    allowDeselection: {
      type: Boolean,
      default: true
    },
    searchable: {
      type: Boolean,
      default: true
    },
    options: Array,
    searchFields: {
      type: Array,
      required: false,
      default() {
        return ["label"];
      }
    }
  },
  data() {
    return {
      isLoading: !!this.query,
      resources: this.options,
      filteredOptions: [],
      resource: this.value,
      quickCreateOpen: false
    };
  },
  apollo: {
    resources: {
      query() {
        return gql`{
          ${this.query} {
            edges {
              node {
                id
                value
                label
                ${this.queryFields.join("\n")}
              }
            }
          }
        }`;
      },
      update(data) {
        this.isLoading = false;
        const result = data[this.query].edges.map(edge => edge.node);

        this.filteredOptions = result;
        return result;
      },
      skip() {
        return !this.query;
      }
    }
  },
  computed: {
    createLabel() {
      return `Add new ${this.modelName}`;
    },
    isDisabled() {
      return (
        this.disabledIfSingle && this.resources && this.resources.length === 1
      );
    },
    shouldShowCreateButton() {
      return this.showCreateButton || !!this.quickCreateComponent;
    },
    inputListeners() {
      const vm = this;

      return Object.assign({}, this.$listeners, {
        create: this.createClicked,
        close: this.dropdownClosed,
        open: this.dropdownOpened,
        selected: evt => vm.$emit("selected", evt),
        input: evt => vm.$emit("input", evt)
      });
    }
  },
  watch: {
    value(newValue) {
      this.resource = newValue;
    },
    options(options) {
      this.resources = options;
    }
  },
  methods: {
    createClicked(type) {
      this.quickCreateOpen = true;
      if (this.quickCreateComponent) {
        this.presentQuickCreateModal();
      } else {
        this.$emit("create", type);
      }
    },
    dropdownClosed(evt) {
      if (!this.quickCreateOpen) {
        this.$emit("close", evt);
      }
    },
    dropdownOpened(evt) {
      this.quickCreateOpen = false;
      this.$emit("open", evt);
    },
    modalName() {
      if (this.quickCreateOpts.modalName) {
        return this.quickCreateOpts.modalName;
      }

      return `quick-create-${this.query}`;
    },
    presentQuickCreateModal() {
      this.$modal.show(this.modalName());
    },
    resourceCreated(resource) {
      this.quickCreateOpen = false;
      this.resource = resource;
      this.resources.push(resource);
      this.$emit("input", resource);
    },
    searchOptions(searchQuery) {
      const queryTerm = searchQuery.toLowerCase();
      const search = resource => {
        const searchFn = field =>
          resource[field].toLowerCase().indexOf(queryTerm) !== -1;
        return this.searchFields.some(searchFn);

        // const results = this.searchFields.map(l => resource[l].toLowerCase().indexOf(queryTerm) !== -1);
        // resource.label.toLowerCase().indexOf(queryTerm) !== -1;
      };

      this.filteredOptions = filter(search, this.resources);
    }
  }
};
</script>

<style scoped>
.ui.form .field > span.resource.dropdown,
.ui.form .field > span.resource.dropdown > .selection.dropdown {
  width: 100%;
}

span.resource.dropdown {
  padding: 0;
}
span.resource.dropdown .quick.create.multiselect__option {
  border-bottom: 1px solid #e8e8e8;
  min-height: 40px;
}
</style>
