export default function calculateMarkup(type, amount, unitCost, quantity) {
  if (type === "PERCENT") {
    return unitCost * quantity * (amount / 100.0);
  }
  if (type === "AMOUNT") {
    return amount;
  }
  if (type === "AMOUNT_PER_UNIT") {
    return amount * quantity;
  }

  return 0.0;
}
