<template lang="pug">
  .ui.button(:class='classList', @click='$emit("click", $event)')
    app-icon(v-if='icon', :icon="icon")
    slot

</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: ""
    },
    blue: Boolean,
    basic: Boolean,
    icon: String,
    labeled: Boolean,
    fluid: Boolean
  },
  computed: {
    classList() {
      let classes = "";
      if (this.size !== "") {
        classes += ` ${this.size}`;
      }
      if (this.blue) {
        classes += " blue";
      }
      if (this.basic) {
        classes += " basic";
      }
      if (this.labeled) {
        classes += " labeled";
      }
      if (this.fluid) {
        classes += " fluid";
      }

      return classes;
    }
  }
};
</script>
