export default {
  invoices: {
    form: {
      project: {
        content: `Select which project this invoice belongs to. ManagePlaces will automatically track
                  all income per project`
      },

      reference: {
        content: `The reference is an identifier you can use to track your invoices. If you have connected
                  ManagePlaces to a third party accounting package, this reference will also be used
                  to link invoices to your projects. ManagePlaces will automatically add the project
                  number to this reference for this purpose.`
      }
    }
  }
};
