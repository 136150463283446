export default {
  items: {
    form: {
      code: {
        content: `The code uniquely identifies the item within ManagePlaces. We will automatically
                  generate one for you if you do not specify one.`
      },
      purchaseAmount: {
        content: `This value will be used to auto-populate line items for purchase orders, bills,
                  change orders and estimates.`
      },
      saleAmount: {
        content:
          "This value will be used to auto-populate line items for invoices."
      }
    }
  }
};
