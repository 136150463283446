<template lang="pug">
  form-modal(:name='name', title='Add an account', :state='state', @closing='modalClosing')
    form
      .fields
        input-wrapper(:validator='$v.account.code', attribute='code', width='four')
          help-label(help-message='finance.accounts.form.code', slot='label') Code

          input.ui.input(
            v-model='account.code',
            placeholder='Code',
            @blur='$v.account.code.$touch()')

        input-wrapper(:validator='$v.account.name', attribute='name', width='twelve')
          label(slot='label') Name

          input.ui.input(
            v-model='account.name',
            placeholder='Enter a name for this account',
            @blur='$v.account.name.$touch()')

      .two.fields
        input-wrapper(:validator='$v.account.accountType', attribute='account type')
          label(slot='label') Account type
          account-type-dropdown(v-model='account.accountType')

        input-wrapper(:validator='$v.account.accountClass', attribute='account class')
          label(slot='label') Account class
          account-class-dropdown(v-model='account.accountClass')

      .actions
        mp-button(@click.prevent='addAccount', blue) Add account
        mp-button(@click.prevent='$modal.hide(name)', basic) Cancel

</template>

<script>
import { required } from "vuelidate/lib/validators";
import InputWrapper from "@/components/inputs/wrappers/FormInputWrapper.vue";
import FormModal from "@/components/modals/FormModal.vue";
import HelpLabel from "@/components/elements/HelpLabel.vue";
import MpButton from "@/components/elements/MpButton.vue";
import createAccountMutation from "@/graphql/mutations/finance/accounts/CreateAccount.gql";
import AccountTypeDropdown from "@/components/inputs/dropdowns/AccountTypeDropdown.vue";
import AccountClassDropdown from "@/components/inputs/dropdowns/AccountClassDropdown.vue";
import FormModalHelpers from "@/mixins/FormModalHelpers";

export default {
  components: {
    FormModal,
    HelpLabel,
    InputWrapper,
    MpButton,
    AccountTypeDropdown,
    AccountClassDropdown
  },
  mixins: [FormModalHelpers],
  data() {
    return {
      account: {},
      name: "quick-create-account",
      state: "none"
    };
  },
  validations: {
    account: {
      code: {
        required
      },
      name: {
        required
      },
      accountType: {
        required
      },
      accountClass: {
        required
      }
    }
  },
  methods: {
    addAccount() {
      this.$v.account.$touch();
      if (!this.$v.account.$invalid) {
        this.saveResource({
          mutation: createAccountMutation,
          variables: {
            input: {
              ...this.account
            }
          }
        }).then(data => {
          this.$emit("created", data.data.createAccount.account);
        });
      }
    },
    modalClosing() {
      this.account = {};
      this.$v.$reset();
    }
  }
};
</script>
