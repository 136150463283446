<template lang="pug">
  number-input(
    placeholder='Set the exchange rate'
    v-model='exchangeRate',
    :precision='10',
    @blur="$emit('blur')",
  )
</template>

<script>
import "whatwg-fetch";
import NumberInput from "@/components/inputs/NumberInput.vue";

export default {
  components: {
    NumberInput
  },
  props: {
    currency: {
      type: Object,
      required: true
    },
    value: {
      type: Number,
      required: false,
      default: 1
    }
  },
  data() {
    return {
      baseCurrencyCode: this.$store.state.defaultCurrency.code,
      exchangeRate: this.value
    };
  },
  computed: {
    currencyCode() {
      return this.currency.code;
    }
  },
  watch: {
    currency() {
      this.refreshExchangeRate();
    },
    exchangeRate() {
      this.$emit("input", this.exchangeRate);
    }
  },
  mounted() {
    if (this.currency && this.baseCurrencyCode !== this.currencyCode) {
      this.refreshExchangeRate();
    }
  },
  methods: {
    refreshExchangeRate() {
      if (this.baseCurrencyCode === this.currencyCode) {
        this.exchangeRate = 1.0;
        return;
      }

      const url = "https://api.exchangeratesapi.io/latest";
      const esc = encodeURIComponent;
      const params = {
        base: this.baseCurrencyCode,
        symbols: this.currencyCode
      };

      const query = Object.keys(params)
        .map(k => `${esc(k)}=${esc(params[k])}`)
        .join("&");

      fetch(`${url}?${query}`)
        .then(response => response.json())
        .then(response => {
          if (response.rates && response.rates[this.currencyCode]) {
            this.exchangeRate = response.rates[this.currencyCode];
          } else {
            this.exchangeRate = 1;
          }
        })
        .catch(() => {
          this.exchangeRate = null;
        });
    }
  }
};
</script>
