import clone from "ramda/src/clone";

export default {
  props: ["value", "index", "description", "task", "currency", "v"],
  data() {
    return {
      lineItem: clone(this.value)
    };
  },
  methods: {
    deleteLineItem() {
      this.$emit("delete");
    }
  },
  watch: {
    value(newValue) {
      this.lineItem = clone(newValue);
    }
  }
};
