<script>
export default {
  props: {
    title: { type: String },
    tag: { type: String, default: "h2" }
  },
  render: function render(h) {
    return h(
      this.tag,
      {
        attrs: {
          class: "thin ui header"
        }
      },
      this.title
    );
  }
};
</script>
