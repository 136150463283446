export default {
  accounts: {
    form: {
      code: {
        content: `The code uniquely identifies the account within ManagePlaces. If you have connected
                  ManagePlaces to an accounting package, this will also need to be unique there as
                  well`
      }
    }
  }
};
