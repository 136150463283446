<template lang="pug">
  label
    slot
    help-tooltip(:message-path='helpMessage')
      app-icon.tooltip-target(icon="question", colour="blue")
</template>

<script>
import HelpTooltip from "@/components/popups/HelpTooltip.vue";

export default {
  components: {
    HelpTooltip
  },
  props: {
    helpMessage: String
  }
};
</script>
