export default {
  inheritAttrs: false,
  props: {
    opts: Object
  },
  data() {
    return {
      state: "none"
    };
  },
  methods: {
    saveResource(mutationOpts) {
      const vm = this;
      vm.state = "loading";

      return this.$apollo
        .mutate(mutationOpts)
        .then(data => {
          vm.state = "success";
          setTimeout(() => {
            vm.$modal.hide(vm.name);
            vm.state = "none";
          }, 1000);

          return data;
        })
        .catch(error => {
          vm.state = "error";
          setTimeout(() => {
            vm.state = "none";
          }, 1000);

          return error;
        });
    }
  }
};
