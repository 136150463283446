<template lang="pug">
  tr
    td.handle.centered
      i.grey.ellipsis.vertical.icon
    td.centered(width='50px') {{ index + 1 }}
    td.input.category.nobreak
      mp-multiselect(
        v-model='lineItem.item',
        :options='items',
        group-values='items',
        group-label='category',
        :group-select='false',
        :show-create-button='true',
        placeholder='Select an item',
        create-label='Add a new item',
        @input='itemSelected',
        @create='$emit("create", "item")',
      )

    input-wrapper(v-if='description', :v='v.description')
      input.ui.input(
        type='text',
        v-model='lineItem.description',
        placeholder='Description',
        @input='$emit("input", lineItem)'
      )

    input-wrapper(v-if='task', :v='v.task')
      mp-multiselect(
        v-model='lineItem[taskModelName]',
        :options='tasks',
        :label='actualTasks ? "label" : null',
        :track-by='actualTasks ? "id" : null',
        :placeholder='actualTasks ? "Select a task" : "Select or add a task"',
        :taggable='true',
        @add-option='$emit("add-option", $event)',
        @input='$emit("input", lineItem)'
      )

    input-wrapper(:v='v.quantity', :classes='["nowrap"]')
      number-input(
        v-model='lineItem.quantity',
        placeholder='Quantity',
        classes='right aligned segment'
        @blur='v.quantity.$touch',
        @keydown.enter.prevent='',
        @input='$emit("input", lineItem)'
      )

    input-wrapper(:v='v.unitCost', :classes='["nowrap"]')
      number-input(
        v-model='lineItem.unitCost',
        placeholder='Cost',
        type='currency',
        :currency='currency',
        ref='unitCost',
        @blur='v.unitCost.$touch',
        @keydown.enter.prevent='',
        @input='$emit("input", lineItem)'
      )

    input-wrapper(:v='v.markupType', :classes='["nowrap"]')
      markup-type-dropdown(
        v-model='lineItem.markupType',
        :currency='currency'
        @blur='v.markupType.$touch',
        @input='$emit("input", lineItem)'
      )

    input-wrapper(:v='v.markupAmount', :classes='["nowrap"]')
      number-input(
        v-model='lineItem.markupAmount',
        placeholder='Amount',
        :type='markupAmountNumberType',
        :currency='currency',
        :read-only='lineItem.markupType && lineItem.markupType === "NONE"'
        @blur='v.markupAmount.$touch',
        @keydown.enter.prevent='',
        @input='$emit("input", lineItem)'
      )

    input-wrapper(:classes='["nowrap"]')
      number-input(
        :value='markupTotal',
        placeholder='Total',
        type='currency',
        :currency='currency',
        :read-only='true',
      )

    input-wrapper(:classes='["nowrap"]')
      number-input(
        :read-only='true',
        :value='totalAmount',
        type='currency',
        :currency='currency'
      )

    td(width='50px')
      mp-button(size='mini', basic, icon='delete', @click.prevent='deleteLineItem()')
</template>

<script>
import FinanceLineItem from "@/mixins/FinanceLineItem";
import MpMultiselect from "@/components/inputs/dropdowns/MpMultiselect.vue";
import MarkupTypeDropdown from "@/components/inputs/dropdowns/MarkupTypeDropdown.vue";
import NumberInput from "@/components/inputs/NumberInput.vue";
import InputWrapper from "@/components/inputs/wrappers/TableCellInputWrapper.vue";
import calculateMarkup from "@/components/utils/CalculateMarkup";
import MpButton from "@/components/elements/MpButton.vue";

export default {
  components: {
    MpMultiselect,
    MarkupTypeDropdown,
    InputWrapper,
    NumberInput,
    MpButton
  },
  mixins: [FinanceLineItem],
  props: {
    tasks: Array,
    items: Array,
    actualTasks: Boolean
  },
  computed: {
    markupAmountNumberType() {
      if (this.lineItem.markupType === "PERCENT") {
        return "percentage";
      }
      if (this.lineItem.markupType === "none") {
        return "number";
      }

      return "currency";
    },
    markupTotal() {
      return calculateMarkup(
        this.lineItem.markupType,
        this.lineItem.markupAmount,
        this.lineItem.unitCost,
        this.lineItem.quantity
      );
    },
    taskModelName() {
      if (this.actualTasks) {
        return "task";
      }

      return "taskName";
    },
    totalAmount() {
      return this.markupTotal + this.quantity * this.unitCost;
    },
    quantity() {
      return this.lineItem.quantity || 0;
    },
    unitCost() {
      return this.lineItem.unitCost || 0;
    }
  },
  watch: {
    "lineItem.markupType": function lineItemMarkupType() {
      this.lineItem.totalAmount = this.totalAmount;
      this.lineItem.markupTotal = this.markupTotal;
    },
    "lineItem.markupAmount": function lineItemMarkupAmount() {
      this.lineItem.totalAmount = this.totalAmount;
      this.lineItem.markupTotal = this.markupTotal;
    },
    "lineItem.quantity": function lineItemQuantity() {
      this.lineItem.totalAmount = this.totalAmount;
      this.lineItem.markupTotal = this.markupTotal;
    },
    "lineItem.unitCost": function lineItemUnitCost() {
      this.lineItem.totalAmount = this.totalAmount;
      this.lineItem.markupTotal = this.markupTotal;
    }
  },
  methods: {
    itemSelected(item) {
      if (this.description) {
        this.lineItem.description = item.purchaseDescription;
      }
      if (!this.lineItem.quantity) {
        this.lineItem.quantity = 0;
      }

      this.lineItem.unitCost = item.purchaseAmount;
      this.$emit("input", this.lineItem);
    }
  }
};
</script>
