import propEq from "ramda/src/propEq";
import filter from "ramda/src/filter";
import map from "ramda/src/map";

function buildTree(nodesList) {
  const childrenOf = nodeId => filter(propEq("parentId", nodeId), nodesList);
  const rootNodes = filter(propEq("parentId", null), nodesList);

  const buildTreeIter = (startNodes, level = 0) => {
    const buildNode = node => ({
      node,
      level,
      children: buildTreeIter(childrenOf(node.id), level + 1, node.id)
    });

    return map(buildNode, startNodes);
  };

  return buildTreeIter(rootNodes, 0);
}

export default buildTree;
