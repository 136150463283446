<template lang="pug">
  .field(:class='classList')
    slot(name='label')

    .message
      span(v-if='hasError') {{ firstErrorMessage }}

    slot
</template>

<script>
import { singleErrorExtractorMixin } from "vuelidate-error-extractor";

export default {
  mixins: [singleErrorExtractorMixin],
  props: ["value", "placeholder", "width", "fullHeight"],
  computed: {
    hasError() {
      if (!this.validator) {
        return false;
      }
      return this.validator.$error;
    },
    classList() {
      let classes = "";

      if (this.hasError) {
        classes += " error";
      }
      if (this.width) {
        classes += ` ${this.width} wide`;
      }
      if (this.fullHeight) {
        classes += " full height";
      }

      return classes;
    }
  }
};
</script>
