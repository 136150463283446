import ContactMessages from "./help_messages/ContactMessages";
import EstimateMessages from "./help_messages/EstimateMessages";
import InvoiceMessages from "./help_messages/InvoiceMessages";
import BillMessages from "./help_messages/BillMessages";
import ChangeOrderMessages from "./help_messages/ChangeOrderMessages";
import ItemMessages from "./help_messages/ItemMessages";
import TaxRateMessages from "./help_messages/TaxRateMessages";
import AccountMessages from "./help_messages/AccountMessages";

export default {
  finance: {
    ...ContactMessages,
    ...EstimateMessages,
    ...InvoiceMessages,
    ...BillMessages,
    ...ChangeOrderMessages,
    ...ItemMessages,
    ...TaxRateMessages,
    ...AccountMessages
  }
};
