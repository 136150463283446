<template lang="pug">
  v-popover(
    :popover-base-class='tooltipClasses',
    trigger='hover',
    placement='auto-start')

    slot
    template(slot="popover")
      .tooltip-title(v-if='helpMessage.title')
        p {{ helpMessage.title }}

      p {{ helpMessage.content }}

      .tooltip-actions(v-if='helpMessage.kbArticle')
        mp-button(fluid, blue, icon='external') Learn more


</template>

<script>
import path from "ramda/src/path";
import messages from "@/config/HelpTooltipMessages";
import MpButton from "@/components/elements/MpButton.vue";

export default {
  components: {
    MpButton
  },
  props: {
    messagePath: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      helpMessage: this.lookupMessage(this.messagePath)
    };
  },
  computed: {
    tooltipClasses() {
      let classes = "mp tooltip";

      if (this.helpMessage.kbArticle) {
        classes += " kb";
      }
      if (this.helpMessage.title) {
        classes += " title";
      }

      return classes;
    }
  },
  methods: {
    lookupMessage(helpPath) {
      return path(helpPath.split("."), messages);
    }
  }
};
</script>
