<template lang="pug">
  resource-dropdown(
    query='currencies',
    :queryFields='queryFields',
    dropdownLabel='Select a currency',
    :value='currency',
    :allow-deselection='false',
    :searchable='false',
    :disabled-if-single='true',
    v-on='$listeners'
  )
</template>

<script>
import clone from "ramda/src/clone";
import ResourceDropdown from "./ResourceDropdown.vue";

export default {
  components: {
    ResourceDropdown
  },
  props: ["value"],
  data() {
    return {
      currency: clone(this.value),
      queryFields: [
        "symbol",
        "code",
        "precision",
        "thousandsSeparator",
        "decimalMark",
        "symbolFirst"
      ]
    };
  },
  watch: {
    value(newCurrency) {
      this.currency = newCurrency;
    }
  }
};
</script>
