import NProgress from "nprogress";

export default (component, opts) => {
  NProgress.start();

  return component.$apollo.mutate(opts).then(data => {
    NProgress.done();
    return data;
  });
};
