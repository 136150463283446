<template lang="pug">
  mp-multiselect(
    :options='options',
    v-model='selectedType',
    :label='null',
    :track-by='null',
    :custom-label='label'
  )
</template>

<script>
import replace from "ramda/src/replace";
import toUpper from "ramda/src/toUpper";
import MpMultiselect from "./MpMultiselect.vue";

export default {
  components: {
    MpMultiselect
  },
  data() {
    return {
      options: [
        "COST_OF_GOODS_SOLD",
        "CURRENT_ASSETS",
        "CURRENT_LIABILITY",
        "EQUITY",
        "EXPENSE",
        "FIXED_ASSETS",
        "INCOME",
        "INVENTORY_ASSETS",
        "LIABILITY",
        "LONG_TERM_LIABILITY",
        "NON_CURRENT_ASSETS",
        "OTHER_EXPENSE",
        "OTHER_INCOME",
        "REVENUE"
      ]
    };
  },
  computed: {
    selectedType: {
      get() {
        return this.value;
      },
      set(type) {
        this.$emit("input", type);
      }
    }
  },
  methods: {
    label(value) {
      return replace(/^./, toUpper)(value.toLowerCase()).replace(/_/g, " ");
    }
  }
};
</script>
