<template lang="pug">
  form-modal(:name='name', title='Add an item', :state='state', @closing='modalClosing')
    form
      .fields
        input-wrapper(:validator='$v.item.code', attribute='code', width='four')
          help-label.optional(help-message='finance.items.form.code', slot='label') Code

          input.ui.input(
            v-model='item.code',
            :placeholder='codePlaceholder')

        input-wrapper(:validator='$v.item.name', attribute='name', width='twelve')
          label(slot='label') Name

          input.ui.input(
            v-model='item.name',
            placeholder='Enter a name for this item',
            @blur='$v.item.name.$touch()')

      .field
        label.optional Description

        textarea(
          v-model='item.description',
          placeholder='Enter a description for this item.',
          :rows='4'
        )

      .two.fields
        input-wrapper(:validator='$v.item.saleAmount', attribute='sale amount')
          help-label.optional(
            help-message='finance.items.form.saleAmount',
            slot='label') Sale amount

          number-input(
            v-model='item.saleAmount',
            placeholder='Sale amount',
            type='currency',
            :currency='$store.state.defaultCurrency',
            @blur='$v.item.saleAmount.$touch',
          )

        input-wrapper(:validator='$v.item.purchaseAmount', attribute='purchase amount')
          help-label.optional(
            help-message='finance.items.form.purchaseAmount',
            slot='label') Purchase amount

          number-input(
            v-model='item.purchaseAmount',
            placeholder='Purchase amount',
            type='currency',
            :currency='$store.state.defaultCurrency',
            @blur='$v.item.purchaseAmount.$touch',
          )
      .actions
        mp-button(@click.prevent='addItem', blue) Add item
        mp-button(@click.prevent='$modal.hide(name)', basic) Cancel

</template>

<script>
import gql from "graphql-tag";
import { required, decimal } from "vuelidate/lib/validators";
import FormModal from "@/components/modals/FormModal.vue";
import HelpLabel from "@/components/elements/HelpLabel.vue";
import MpButton from "@/components/elements/MpButton.vue";
import InputWrapper from "@/components/inputs/wrappers/FormInputWrapper.vue";
import NumberInput from "@/components/inputs/NumberInput.vue";
import createItemMutation from "@/graphql/mutations/finance/items/CreateItem.gql";
import FormModalHelpers from "@/mixins/FormModalHelpers";

export default {
  components: {
    FormModal,
    HelpLabel,
    InputWrapper,
    NumberInput,
    MpButton
  },
  mixins: [FormModalHelpers],
  data() {
    return {
      codePlaceholder: "Code",
      item: {},
      name: "quick-create-item",
      state: "none"
    };
  },
  apollo: {
    codePlaceholder: {
      query() {
        return gql`
          {
            sequence(sequenceType: "item") {
              nextValue
            }
          }
        `;
      },
      update(data) {
        return data.sequence.nextValue;
      }
    }
  },
  validations: {
    item: {
      name: {
        required
      },
      purchaseAmount: {
        decimal
      },
      saleAmount: {
        decimal
      }
    }
  },
  methods: {
    addItem() {
      this.$v.item.$touch();
      if (!this.$v.item.$invalid) {
        this.saveResource({
          mutation: createItemMutation,
          variables: {
            input: {
              ...this.item
            }
          }
        }).then(data => {
          this.$emit("created", data.data.createItem.item);
        });
      }
    },
    modalClosing() {
      this.item = {};
      this.$v.$reset();
    }
  }
};
</script>
