export default {
  bills: {
    form: {
      project: {
        content: `Select which project this bill belongs to. ManagePlaces will automatically track
                  all income per project`
      },

      reference: {
        content: `The bill identifier provided to you by the supplier. If you have connected ManagePlaces
                  to a third party accounting package, this reference will also be used to link to your
                  projects. ManagePlaces will automatically add the project number to this reference
                  for this purpose`
      }
    }
  }
};
