<template lang="pug">
  form-modal(:name='name', title='Add a tax rate', :state='state', @closing='modalClosing')
    form
      input-wrapper(:validator='$v.taxRate.name', attribute='name')
        label(slot='label') Tax rate name
        input.ui.input(
          placeholder='What is this tax rate called?',
          v-model='taxRate.name',
          @blur='$v.taxRate.name.$touch()')

      .two.fields
        input-wrapper(:validator='$v.taxRate.displayRate', attribute='display rate')
          help-label(help-message='finance.taxRates.form.displayRate', slot='label') Display rate

          number-input(
            placeholder='Display rate',
            v-model='taxRate.displayRate',
            type='percentage',
            @blur='$v.taxRate.displayRate.$touch()')

        input-wrapper(:validator='$v.taxRate.effectiveRate', attribute='effective rate')
          help-label(
            help-message='finance.taxRates.form.effectiveRate',
            slot='label') Effective rate

          number-input(
            placeholder='Effective rate',
            v-model='taxRate.effectiveRate',
            type='percentage',
            @blur='$v.taxRate.effectiveRate.$touch()')

      .actions
        mp-button(blue, @click.prevent='addTaxRate') Add tax rate
        mp-button(basic, @click.prevent='$modal.hide(name)') Cancel
</template>

<script>
import { required, decimal } from "vuelidate/lib/validators";
import FormModal from "@/components/modals/FormModal.vue";
import HelpLabel from "@/components/elements/HelpLabel.vue";
import InputWrapper from "@/components/inputs/wrappers/FormInputWrapper.vue";
import createTaxRateMutation from "@/graphql/mutations/finance/tax_rates/CreateTaxRate.gql";
import FormModalHelpers from "@/mixins/FormModalHelpers";
import MpButton from "@/components/elements/MpButton.vue";
import NumberInput from "@/components/inputs/NumberInput.vue";

export default {
  components: {
    FormModal,
    HelpLabel,
    InputWrapper,
    MpButton,
    NumberInput
  },
  mixins: [FormModalHelpers],
  validations: {
    taxRate: {
      name: {
        required
      },
      displayRate: {
        required,
        decimal
      },
      effectiveRate: {
        required,
        decimal
      }
    }
  },
  data() {
    return {
      state: "none",
      name: "quick-create-tax-rates",
      taxRate: {}
    };
  },
  methods: {
    addTaxRate() {
      this.$v.taxRate.$touch();
      if (!this.$v.taxRate.$invalid) {
        this.saveResource({
          mutation: createTaxRateMutation,
          variables: {
            input: {
              ...this.taxRate
            }
          }
        }).then(data => {
          this.$emit("created", data.data.createTaxRate.taxRate);
        });
      }
    },
    modalClosing() {
      this.taxRate = {};
      this.$v.$reset();
    }
  }
};
</script>
