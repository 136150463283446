<template lang="pug">
  multiselect(
    placeholder='Type',
    v-model='markupType',
    :options='markupTypes',
    :searchable='false',
    selectLabel='',
    deselectLabel='',
    :custom-label='label',
    @close='onBlurHandler'
  )
</template>

<script>
import clone from "ramda/src/clone";

export default {
  props: ["value", "currency"],
  data() {
    return {
      markupType: clone(this.value)
    };
  },
  computed: {
    markupTypes() {
      return ["PERCENT", "AMOUNT", "AMOUNT_PER_UNIT", "NONE"];
    },
    currencySymbol() {
      if (!this.currency) {
        return "£";
      }
      return this.currency.symbol;
    }
  },
  watch: {
    markupType(val) {
      this.$emit("input", val);
    }
  },
  methods: {
    label(value) {
      let lbl;

      switch (value) {
        case "PERCENT": {
          lbl = "%";
          break;
        }
        case "AMOUNT": {
          lbl = this.currencySymbol;
          break;
        }
        case "AMOUNT_PER_UNIT": {
          lbl = `${this.currencySymbol}/unit`;
          break;
        }
        case "NONE": {
          lbl = "None";
          break;
        }
        default: {
          lbl = "None";
          break;
        }
      }

      return lbl;
    },
    onBlurHandler(e) {
      this.$emit("blur", e);
    }
  }
};
</script>
