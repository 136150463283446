export default {
  data() {
    return {
      status: "DRAFT",
      amountsTaxInclusive: false,
      lineItems: [],
      currency: {},
      contact: null,
      issued_date: null,
      deadline: null
    };
  }
};
