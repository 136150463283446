<template lang="pug">
  multiselect(
    placeholder='Select a status',
    track-by='value',
    label='text',
    v-model='status',
    :allow-empty='false',
    :options='statuses',
    selectLabel='',
    deselectLabel=''
  )
</template>

<script>
export default {
  props: ["value", "variation"],
  data() {
    return {
      status: {
        text: this.toLabel(this.value),
        value: this.toValue(this.value)
      }
    };
  },
  computed: {
    statuses() {
      switch (this.variation) {
        case "invoice":
          return this.statusList([
            "draft",
            "submitted",
            "authorised",
            "paid",
            "voided"
          ]);
        case "bill":
          return this.statusList([
            "draft",
            "submitted",
            "authorised",
            "paid",
            "voided"
          ]);
        case "estimate":
          return this.statusList([
            "draft",
            "submitted",
            "authorised",
            "rejected"
          ]);
        case "purchase_order":
          return this.statusList([
            "draft",
            "submitted",
            "authorised",
            "billed"
          ]);
        case "change_order":
          return this.statusList([
            "draft",
            "submitted",
            "authorised",
            "rejected"
          ]);
        default:
          return [];
      }
    }
  },
  watch: {
    status(status) {
      this.$emit("input", status.value);
    }
  },
  methods: {
    statusList(list) {
      return list.map(status => ({
        text: this.toLabel(status),
        value: this.toValue(status)
      }));
    },
    toLabel(status) {
      return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    },
    toValue(status) {
      return status.toUpperCase();
    }
  }
};
</script>
