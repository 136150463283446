export default {
  contacts: {
    form: {
      name: {
        content:
          "If the contact is an individual rather than a company, enter their name here."
      }
    }
  }
};
