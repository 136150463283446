<template lang="pug">
  .ui.one.column.grid
    .column
      create-item-modal(@created='itemCreated')
      create-account-modal(@created='accountCreated')
      create-tax-rate-modal(@created='taxRateCreated')

      table.static.celled.mp.table
        markup-header(
          v-if='structure.type === "markup"',
          :description='structure.description',
          :task='structure.task'
        )

        taxable-header(
          v-if='structure.type === "taxable"',
          :description='structure.description',
          :task='structure.task'
        )

        tbody(
          v-if='structure.type === "markup"',
          is='draggable',
          element='tbody',
          :list='model.lineItems',
          :options='{ draggable: "tr", handle: ".handle" }'
          )

          markup-line-item(
            v-for='(lineItem, index) in model.lineItems',
            :index='index',
            :key='lineItem.key',
            v-model='model.lineItems[index]',
            :description='structure.description',
            :task='structure.task',
            :items='items',
            :currency='currency',
            :v='v.$each[index]',
            :tasks='lineItemTasks',
            :actual-tasks='!!(model.project && model.project.id)'
            @create='presentCreationModal($event, index)',
            @delete='deleteLineItem(index)',
            @add-option='addNewTask($event, index)',
            @input='lineItemChanged($event, index)'
          )

        tbody(
          v-if='structure.type === "taxable"',
          is='draggable',
          element='tbody',
          :list='model.lineItems',
          :options='{ draggable: "tr", handle: ".handle" }'
          )

          taxable-line-item(
            v-for='(lineItem, index) in model.lineItems',
            :index='index',
            :key='lineItem.key',
            v-model='model.lineItems[index]',
            :description='structure.description',
            :task='structure.task',
            :items='items',
            :currency='currency',
            :v='v.$each[index]',
            :tax-rates='taxRates',
            :accounts='accounts',
            :project='project',
            @create='presentCreationModal($event, index)',
            @delete='deleteLineItem(index)',
            @input='lineItemChanged($event, index)'
          )
</template>

<script>
import gql from "graphql-tag";
import map from "ramda/src/map";
import propOr from "ramda/src/propOr";
import reject from "ramda/src/reject";
import isEmpty from "ramda/src/isEmpty";
import uniq from "ramda/src/uniq";
import groupBy from "ramda/src/groupBy";
import toPairs from "ramda/src/toPairs";
import clone from "ramda/src/clone";
import CreateItemModal from "@/components/modals/CreateItemModal.vue";
import CreateAccountModal from "@/components/modals/CreateAccountModal.vue";
import CreateTaxRateModal from "@/components/modals/CreateTaxRateModal.vue";
import ItemQuery from "@/graphql/queries/finance/items/ItemDropdown.gql";
import TaxRatesQuery from "@/graphql/queries/finance/tax_rates/LineItemTaxRates.gql";
import buildTree from "@/helpers/BuildTree";
import treeDropdownFormatter from "@/helpers/TreeDropdownFormatter";
import MarkupLineItem from "./line_items/MarkupTypeLineItem.vue";
import MarkupHeader from "./line_items/MarkupTypeHeader.vue";
import TaxableLineItem from "./line_items/TaxableTypeLineItem.vue";
import TaxableHeader from "./line_items/TaxableTypeHeader.vue";

export default {
  components: {
    CreateItemModal,
    MarkupLineItem,
    MarkupHeader,
    TaxableLineItem,
    TaxableHeader,
    CreateAccountModal,
    CreateTaxRateModal
  },
  props: ["structure", "value", "currency", "v", "project"],
  apollo: {
    items: {
      query: ItemQuery,
      update(data) {
        const grouped = groupBy(
          node => (node.category ? node.category.name : "Uncategorised"),
          data.items.edges.map(edge => edge.node)
        );

        return map(
          v => ({
            category: v[0],
            items: treeDropdownFormatter(buildTree(v[1]))
          }),
          toPairs(grouped)
        );
      }
    },
    projectTasks: {
      query() {
        return gql`{
          project(id: "${this.model.project.id}") {
            id
            tasks(sortDirection: ASC, sortField: POSITION) {
              edges {
                node {
                  id
                  value
                  label
                  parentId
                }
              }
            }
          }
        }`;
      },
      update(data) {
        return treeDropdownFormatter(
          buildTree(data.project.tasks.edges.map(edge => edge.node))
        );
      },
      skip() {
        return !this.model.project;
      }
    },
    taxRates: {
      query: TaxRatesQuery,
      update(data) {
        return data.taxRates.edges.map(edge => edge.node);
      },
      skip() {
        return this.structure.type !== "taxable";
      }
    },
    accounts: {
      query() {
        return gql`
          {
            accounts {
              edges {
                node {
                  id
                  name
                  accountClass
                }
              }
            }
          }
        `;
      },
      update(data) {
        return data.accounts.edges.map(edge => edge.node);
      },
      skip() {
        return this.structure.type !== "taxable";
      }
    }
  },
  data() {
    return {
      newTaskNames: uniq(
        reject(isEmpty, map(propOr("", "taskName"), this.value.lineItems))
      ),
      openModal: null,
      model: clone(this.value)
    };
  },
  computed: {
    lineItemTasks() {
      if (this.model.project) {
        // Need to show the actual tasks, not just task names
        return this.projectTasks;
      }
      return this.newTaskNames;
    }
  },
  watch: {
    value(newValue) {
      this.model = clone(newValue);
    }
  },
  methods: {
    accountCreated(account) {
      this.accounts.push(account);
      this.model.lineItems[this.callingLineItemIndex].account = account;
      this.callingLineItemIndex = null;
      this.$emit("input", this.model);
    },
    addNewTask(taskName, index) {
      this.newTaskNames.push(taskName);
      this.model.lineItems[index].taskName = taskName;
      this.$emit("input", this.model);
    },
    deleteLineItem(idx) {
      this.$emit("delete-line-item", idx);
    },
    itemCreated(item) {
      this.items.push(item);
      this.model.lineItems[this.callingLineItemIndex].item = item;
      this.callingLineItemIndex = null;
      this.$emit("input", this.model);
    },
    lineItemChanged(model, index) {
      this.model.lineItems[index] = model;
      this.$emit("input", this.model);
    },
    presentCreationModal(type, index) {
      this.openModal = type;
      this.callingLineItemIndex = index;
      this.$modal.show(`quick-create-${type}`);
    },
    taxRateCreated(taxRate) {
      this.taxRates.push(taxRate);
      this.model.lineItems[this.callingLineItemIndex].taxRate = taxRate;
      this.callingLineItemIndex = null;
      this.$emit("input", this.model);
    }
  }
};
</script>
