export default {
  changeOrders: {
    form: {
      name: {
        content:
          "This name will help you quickly identify a change order and why it was created."
      }
    }
  }
};
