<template lang="pug">
  app-dropdown-field(
    v-model="contact"
    v-on="$listeners"
    :show-optional="false"
    :options="contacts"
    label-attr="name"
    track-by="id"
    @input="contactChanged")

    template(#beforeList v-if="permissions.canCreate")
      .cursor-pointer.p-4.border-b.border-grey-30(@click="addContact")
        app-icon(icon="add")
        span.ml-4 Add a new contact
</template>

<script>
import clone from "ramda/src/clone";
import ContactsQuery from "@/graphql/queries/contact_management/Contacts.gql";
import NewContactSidebar from "@/components/contact_management/new_contact_sidebar/NewContactSidebar.vue";
import { cacheUpdate, appendToCache } from "@/helpers/GraphQLHelpers";

export default {
  props: {
    value: {
      type: Object,
      required: false,
      default() {
        return null;
      }
    }
  },
  apollo: {
    contacts: {
      query: ContactsQuery,
      update(data) {
        return data.contacts.edges.map(({ node }) => node);
      },
      result({ data }) {
        this.permissions.canCreate = data.contacts.canCreate;
      }
    }
  },
  data() {
    return {
      contact: clone(this.value),
      contacts: [],
      permissions: {
        canCreate: false
      }
    };
  },
  watch: {
    value(newContact) {
      this.contact = newContact;
    }
  },
  methods: {
    addContact() {
      const vm = this;
      this.$sidebar.open(NewContactSidebar, {
        on: {
          created(contact) {
            // New contact added.
            vm.contact = contact;
            cacheUpdate(
              cachedData => appendToCache(cachedData, "contacts", [contact]),
              ContactsQuery
            );
            // Shouldn't have to do this, but caching isn't working properly
            // and is only updating the UI in some circumstances
            vm.contacts.push(contact);
            vm.contactChanged();
          }
        }
      });
    },
    contactChanged() {
      this.$emit("input", this.contact);
    }
  }
};
</script>
