<template lang="pug">
  td.input(:class='classList')
    slot
</template>

<script>
export default {
  props: {
    v: Object,
    classes: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    classList() {
      const classes = this.classes.slice(0);
      classes.push({ error: this.hasError() });
      return classes;
    }
  },
  methods: {
    hasError() {
      return this.v && this.v.$error;
    }
  }
};
</script>
