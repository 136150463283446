/**
 * Finds the first error on a page a scrolls the
 * view so it becomes visible to the user.
 */
export default component => {
  component.$nextTick(() => {
    const element = ".field.error, .fields.error";
    component.$scrollTo(element, 250);
  });
};
